/** @format */

// font sizes
@for $i from 1 through 100 {
  .th-#{$i} {
    font-size: $i + px !important;
  }
}

// font weight
@for $i from 100 through 900 {
  @if $i % 100==0 {
    .th-fw-#{$i} {
      font-weight: $i;
    }
  }
}

//   border radius
@for $i from 1 through 100 {
  .th-br-#{$i} {
    border-radius: $i + px !important;
  }
}

//   Line Height
@for $i from 1 through 100 {
  .th-lh-#{$i} {
    line-height: $i + px;
  }
}

//   Width
@for $i from 1 through 100 {
  .th-width-#{$i} {
    width: percentage($number: $i) / 100 !important;
  }
}
//   Width
@for $i from 1 through 100 {
  .th-height-#{$i} {
    height: percentage($number: $i) / 100 !important;
  }
}

// Text-Truncate
@for $i from 1 through 100 {
  .th-truncate-#{$i} {
    -webkit-line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
