/** @format */
.th-menu-profile-icon {
  position: absolute;
  bottom: 1%;
  left: 20%;
}
.th-border {
  border: 1px solid #eaecf5;
}
.th-border-primary {
  border: 1px solid #444ce7 !important;
}
.th-border-1 {
  border: 1px solid #c7d7fe;
}
.th-border-grey {
  border: 1px solid #98a2b3;
}
.th-border-1-dashed {
  border: 1px dashed #c7d7fe;
}
.th-border-2 {
  border: 1px solid #b3b8db;
}
.th-border-3 {
  border: 0.5px solid #d0d5dd;
}
.th-border-4 {
  border: 1px solid #d0d5dd;
}
.th-border-5 {
  border: 1px solid #e9ebf1;
}
.th-border-6 {
  border: 1px solid #eaecf0;
}
.th-border-7 {
  border: 1px solid #d0d5dd;
}
.th-border-yellow {
  border: 0.5px solid #f79009;
}
.th-border-blue-200 {
  border: 1px solid #d5d9eb;
}
.th-border-blue-500 {
  border: 1px solid #8098f9;
}
.th-border-left-blue {
  border-left: 2px solid #717bbc;
}
.th-border-primary-2 {
  border: 2px solid #444ce7 !important;
}
.th-dashed-border {
  border: 1px dashed #eaecf5 !important;
}
.th-dashed-border-2 {
  border: 2px dashed #eaecf5 !important;
}
.th-border-bottom {
  border-bottom: 1px solid #eaecf5;
}
.th-border-error {
  border: 1px solid #d92d20;
}
.th-page-header {
  height: 56px;
  display: flex;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  z-index: 100;
}
.th-message:hover {
  background-color: #fff !important;
}
.th-divider-h {
  height: 1px;
  background-color: #eaecf0;
}
.th-selected-tag {
  outline: 2px solid #444ce7;
}
.submenu-item:hover {
  background-color: #eaecf5;
}
.th-warning-header {
  height: 56px;
  padding: 8px 10px;
  background-color: #fffaeb;
}
.th-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1d2939;
}
.th-heading-2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
}
.th-heading-error {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #d92d20;
}
.th-heading-error-2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #d92d20;
}
.th-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.th-table-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085 !important;
}
.th-workspace-tabs .ant-tabs-nav {
  padding: 0 24px !important;
  margin: 0 !important;
  position: sticky;
  top: 56px;
  z-index: 2;
  width: 100%;
  background: #ffffff;
}
.th-action-list {
  border: 1px solid #eaecf5;
  border-radius: 8px !important;
  > div {
    &:first-child {
      border-bottom: 1px solid #eaecf5;
    }
  }
}
.th-custom-color-picker .ant-color-picker-color-block {
  height: 100% !important;
  width: 100% !important;
  border-radius: 24px !important;
  position: relative;
}
.collapse-icon {
  padding: 5px;
}
.collapse-icon:hover {
  background-color: #eaecf5 !important;
  border-radius: 50% !important;
  // padding: 5px;
}
.th-outline-none {
  outline: none;
}
.th-teams-tabs .ant-tabs-nav {
  padding: 0px 24px !important;
}
.th-settings-tab .ant-tabs .ant-tabs-tabpane {
  background-color: #fff !important;
}
.th-profile-link {
  cursor: pointer !important;
  color: #101828;
  :hover {
    text-decoration: underline !important;
    color: #444ce7 !important;
  }
}
.th-profile-image-icon {
  position: absolute;
  bottom: -5px;
  right: -5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.th-hire-me-button-title {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}
.th-hire-me-card-light-mode {
  background-color: #1d2939 !important;
  .th-hire-me-button-logo {
    background-color: #ffffff !important;
  }
  .th-hire-me-button {
    color: #1d2939;
    background-color: #ffffff !important;
  }
  .th-hire-me-button-title {
    color: #1d2939 !important;
  }
}
.th-hire-me-card-dark-mode {
  background-color: #ffffff !important;
  .th-hire-me-button-logo {
    background-color: #1d2939 !important;
  }
  .th-hire-me-button {
    color: #ffffff !important;
    background-color: #1d2939 !important;
  }
  .th-hire-me-button-title {
    color: #ffffff !important;
  }
}
.th-hire-me-modal .ant-modal-header {
  padding: 0px !important;
  border-bottom: none !important;
}
.overlay-card-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(214, 202, 202, 0.5);
  z-index: 1;
  display: block;
  border-radius: 8px !important;
}
.th-workspace-card {
  .ant-card {
    transition: all 0.5s ease; 
    &:hover {
      background-color: #f8f9fa !important;
      border-color: #204492 !important;
    }
  }
}
.th-custom-message .ant-message-custom-content {
  display: flex !important;
  align-items: center !important;
}
.th-custom-placeholder .ant-select-selection__placeholder {
  color: blue !important;
}
