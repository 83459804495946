/** @format */

// BUTTON
.ant-btn {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.th-custom-icon-button .ant-btn {
  display: flex !important;
  align-items: center !important;
}

// SELECT
.th-dashed-select .ant-select-selector {
  border: 1px dashed #d5d9eb !important;
}

.th-client-select .ant-select-dropdown .ant-select-item-option-selected {
  background-color: #f5f8ff !important;
  border-left: 2px solid #444ce7 !important;
}
// TABLE
thead[class*="ant-table-thead"] th {
  padding: 5px 16px !important;
  color: #667085;
  font-weight: 400 !important;
  border-radius: 0% !important;
  height: 40px !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #eaecf5 !important;
  color: #667085 !important;
  font-weight: 400 !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #eaecf5 !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 2rem !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  padding-right: 2rem !important;
}
.ant-table-tbody > tr > td.ant-table-cell:first-child {
  padding-left: 2rem !important;
}

.ant-table-tbody > tr > td.ant-table-cell:last-child {
  padding-right: 2rem !important;
}

// AVATAR
.th-home-avatar .ant-avatar-circle:last-child {
  height: 24px !important;
  width: 24px !important ;

  .ant-avatar-string {
    transform: scale(0.75) translate(-50%, -15%) !important;
  }
}
// AVATAR
.ant-tooltip-inner {
  max-height: 300px !important;
  overflow-y: auto !important ;

  .ant-avatar-string {
    transform: scale(0.75) translate(-50%, -15%) !important;
  }
}

// CARD
.ant-card {
  border: 1px solid #eaecf5 !important;
}
.th-borderless-card .ant-card {
  border: none !important;
}
.th-no-padding-modal .ant-card {
  border-radius: 0px !important;
}
.th-no-padding-modal .ant-modal-body {
  padding: 0px !important;
}
// MODAL
.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-header {
  padding: 16px 24px !important;
  border-bottom: 1px solid #d9d9d9 !important;
  margin-bottom: 0px !important;
}
.ant-modal-body {
  padding: 0px 16px !important;
}
.ant-modal-footer {
  padding: 16px !important;
  border-top: 1px solid #d9d9d9 !important;
}
.ant-modal-footer {
  margin-top: 0px !important;
}

.th-no-padding-modal .ant-modal-body {
  padding: 0px !important;
}

.th-decline-contract-modal .ant-modal-header {
  padding: 16px 24px 0px 24px !important;
  border-bottom: 0px solid #d9d9d9 !important;
  margin-bottom: 0px !important;
}

.th-decline-contract-modal .ant-modal-body {
  padding: 0px 16px 16px 16px !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.th-approve-task-footer .ant-modal-footer {
  display: none !important;
}

// POPOVER
.ant-popover-inner {
  padding: 0px !important;
}

.ant-popconfirm .ant-popover-inner {
  padding: 8px !important;
}

// DRAWER
.th-drawer .ant-drawer-body {
  padding: 0px !important;
}

// DIVIDER
.ant-divider {
  border: 1px solid #d5d9eb;
}

// TABS
.ant-tabs-tab-btn {
  color: #667085 !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #444ce7 !important;
}

// COLLAPSE
.ant-collapse .ant-collapse-content {
  border-top: none !important;
}

// FORM
.ant-form-item-required {
  color: #475467 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.th-opportunity-form .ant-form-item-required {
  color: #1d2939 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.th-opportunity-form
  .ant-form-item-label
  > label.ant-form-item-required::before {
  content: none !important;
}

.th-antd-form-label-nowrap label {
  white-space: nowrap;
}

.ant-form-item-label > label {
  color: #667085 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.label.ant-form-item-required::before {
  display: none !important;
}
.ant-form-item-required label::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.th-auth-segment .ant-segmented {
  background: linear-gradient(
    180deg,
    #eef4ff 0%,
    rgba(238, 244, 255, 0) 100%
  ) !important;
  border-radius: 16px !important;
  box-shadow: 0px -1px 1px 0px #0000001a inset !important;
  .ant-segmented-group {
    display: flex !important;
    justify-content: space-between !important;
    gap: 8px !important;
  }
  .ant-segmented-group {
    padding: 2px !important;
  }
  .ant-segmented-item {
    border-radius: 16px !important;
    width: 50% !important;
  }
}

.th-profile .ant-segmented {
  background-color: #eaecf5 !important;
  // border: 1px solid #d9d9d9 !important;
  .ant-segmented-item {
    color: #475467 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  .ant-segmented-item-selected {
    color: #1d2939 !important;
  }
}

.th-opportunity-list .ant-list-item {
  padding: 0px 12px !important;
}
